

































import {
  Component, Emit, Prop, Vue, Watch,
} from "vue-property-decorator";
import debounce from "lodash/debounce";
import lodash from "lodash";

const READ_STATE = "READ";
const EDIT_STATE = "EDIT";

@Component({})
export default class ReadEditAutocomplete extends Vue {
  READ_STATE = READ_STATE;

  EDIT_STATE = EDIT_STATE;

  loading = false;

  state = READ_STATE;

  searchString: string | null = null;

  @Prop()
  item!: any;

  newItem = this.item;

  matchedValues: any[] = this.item ? [this.item] : [];

  @Prop()
  label!: string;

  @Prop()
  isMayBeEmpty!: boolean;

  @Prop()
  dispatchPath!: string;

  @Prop()
  itemName!: string;

  @Watch("item")
  onChangeItem() {
    if (this.item) {
      this.matchedValues = [this.item];
    }
  }

  @Watch("searchString")
  onChangeSearchString() {
    if (this.searchString) {
      this.loadFilteredValues();
    }
  }

  get disableSave() {
    return (!this.newItem && !this.isMayBeEmpty)
      || (!this.item && !this.newItem)
      || lodash.isEqual(this.item, this.newItem);
  }

  @Emit("onChange")
  emitNewItem() {
    return this.newItem;
  }

  onChange(listItem: any) {
    this.newItem = listItem;
  }

  editField() {
    this.state = EDIT_STATE;
  }

  readField() {
    this.state = READ_STATE;
  }

  saveNewValue() {
    if (this.disableSave) return;

    this.emitNewItem();

    if (this.newItem) {
      this.readField();
    }
  }

  loadFilteredValues() {}

  mounted() {
    if (!this.item) {
      this.editField();
    }

    this.loadFilteredValues = debounce(async () => {
      if (!this.searchString) return;

      this.loading = true;
      this.matchedValues = await this.$store.dispatch(this.dispatchPath, this.searchString);
      this.loading = false;
    }, 300);
  }
}
